import React from 'react';
import styled from 'styled-components';

const YouTubeShowcase = ({trackEvent}) => {
  const youtubeVideoId = 'dQw4w9WgXcQ'; // Replace with your actual YouTube video ID
  const thumbnailUrl = `https://cdn.pixabay.com/photo/2020/04/04/03/42/youtube-5000699_640.png`;
  const videoUrl = `https://www.youtube.com/@TheScriptStream/videos`;

  return (
    <ShowcaseWrapper onClick={() => trackEvent('Clicks anywhere on showcase')}>
      <ShowcaseTitle>We take pride in our work:</ShowcaseTitle>
      <ThumbnailWrapper>
        <ThumbnailImage src={thumbnailUrl} alt="Latest work thumbnail" />
        <ThumbnailOverlay href={videoUrl} target="_blank" rel="noopener noreferrer">

        </ThumbnailOverlay>
      </ThumbnailWrapper>
      <ShowcaseDescription>Click the image to watch our latest sample</ShowcaseDescription>
    </ShowcaseWrapper>
  );
};

const ShowcaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  background-color: #f8f8f8;
`;

const ShowcaseTitle = styled.h2`
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  max-width: 640px;
  width: 100%;
  transition: transform 0.3s ease;

  padding: 16px;
  &:hover {
    transform: scale(1.05);
  }
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ThumbnailOverlay = styled.a`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const ShowcaseDescription = styled.p`
  font-size: 18px;
  color: #666;
  margin-top: 16px;
  text-align: center;
`;

export default YouTubeShowcase;