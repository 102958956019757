import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
// Components
import ClientSlider from '../Elements/ClientSlider';
import ServiceBox from '../Elements/ServiceBox';
import FullButton from '../Buttons/FullButton';
// Assets
import AddImage1 from '../../assets/img/add/1.png';
import AddImage2 from '../../assets/img/add/2.png';
import AddImage3 from '../../assets/img/add/3.png';
import AddImage4 from '../../assets/img/add/4.png';
import Showcase from './Showcase';

export default function Services({ trackEvent }) {
  return (
    <Wrapper id="services">
    
      <Showcase trackEvent={trackEvent} />
      <div className="" style={{ padding: '60px 0' }}>
        <div className="container center">
          <HeaderInfo>
            <h1 className="boldHeader font50 extraBold">We didn't reinvent the wheel. Just content creation</h1>
            <br></br>
            <p className="boldSubHeader font18">
              Our services are designed to help you get the most out of your content creation process.
              <br />
              Say goodbye to your old style of producing content.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex center-row p16">
            <ServiceBoxWrapper>
              <ServiceBox icon="bell" title="" subtitle="Subscribe to a plan & request any script you want." />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="monitor" title="" subtitle="Receive your script within two business days on average." />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="printer" title="" subtitle="Work with our editors to achieve the perfect script." />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <center>
                  <p class="center">
                    <br></br>
                    <b>What customers are saying:</b>
                  </p>
                </center>
                <h2 className="font40 extraBold">
                  It's <i>"you'll never go back"</i> better.
                </h2>
                <br></br>
                <div className="center">
                  <p className="font16">ScriptStream enhances the experience of hiring content writers by offering quality for one flat monthly fee, with scripts delivered so fast that it will blow your mind.</p>
                  <ButtonsRow className="flexNullCenter" style={{ margin: '30px 0' }}>
                    <div style={{ width: '190px' }}>
                      <FullButton to="pricing" title="See plans" onClick={() => trackEvent('Click "See plans" button on services section')} />
                    </div>
                    <div style={{ width: '190px' }}>
                      <Link to="contact" smooth={true} offset={-80}>
                        <FullButton action={() => {
                          trackEvent('Click "Contact Us" button on services section');
                          window.open('mailto:hello@scriptstream.co', '_blank');
                        }} title="Contact Us" border />
                      </Link>
                    </div>
                  </ButtonsRow>
                </div>
              </AddLeft>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  display: flex;
  gap: 40px;
  @media (max-width: 860px) {
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
`;
const AddLeft = styled.div`
  width: 100%;
  text-align: center;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;

const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
