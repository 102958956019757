import React, {useEffect, useState} from "react";
import styled from "styled-components";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Pricing from "../components/Sections/Pricing";
import Footer from "../components/Sections/Footer"
import FAQ from "../components/Sections/FAQ";
import PromoBanner from "../components/Sections/PromoBanner";
import { RudderAnalytics } from '@rudderstack/analytics-js';

export default function Landing() {
  const [rudderAnalytics, setRudderAnalytics] = useState(null);

  useEffect(() => {
    const analytics = new RudderAnalytics();
    analytics.load('2mx3xvrl5xO1v1W4YFLpiRCrO30', 'https://scriptstreqvem.dataplane.rudderstack.com', {
      sessions: {
        autoTrack: true,
        timeout: 10 * 60 * 1000,  // 10 min in milliseconds
      },
    });
    setRudderAnalytics(analytics);
  }, []);
  

  const trackEvent = (eventName, value) => {
    if (rudderAnalytics) {
      console.log(`Tracking event: ${eventName}`);
      rudderAnalytics.track(eventName, { property: value });
    }
  };

  const props = {
    trackEvent: (eventName, value) => trackEvent(eventName, value),
  };

  return (
    <>
    {/* <PromoBanner /> */}
    <Wrapper>
      <TopNavbar {...props} />
      <Header {...props} />
      <Services {...props} />
      <Projects {...props} />
      <Pricing {...props} />
      <FAQ {...props} />
      <Footer {...props} />
    </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
    background-image: url(https://cdn.prod.website-files.com/5837424ae11409586f837994/65da2485ff5fcdc97cd23ac3_dots.svg),url(https://cdn.prod.website-files.com/5837424ae11409586f837994/65e0c422ce2b0db3548c99a5_Frame%202316.png);
    background-position: 0 0,0 0;
    background-size: auto,auto;
    border-radius: 36px;
    margin-left: auto;
    margin-right: auto;

    & section {
      background-color: transparent;
    }
`;


