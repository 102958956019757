import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
// Components
import FullButton from '../Buttons/FullButton';
// Assets
import HeaderImage from '../../assets/img/script-header.png';
import QuotesIcon from '../../assets/svg/Quotes';
import Dots from '../../assets/svg/Dots';
import Lottie from 'react-lottie';
import RedDot from '../../lotties/red-dot.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: RedDot,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function Header({trackEvent}) {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">
            Create content with a <i>twist</i>
          </h1>
          <HeaderP className="font18 semiBold">
            Content subscriptions for everyone. <br></br> <i>Pause or cancel anytime.</i>
          </HeaderP>
          <FullButton to="pricing" title="See plans" onClick={() => {
             trackEvent("Clicked on 'See plans' button in hero section", "Top navbar");
          }} />
          <LottieAnimation data-w-id="f8b46005-0252-910f-0790-c51ccade384b" class="available-component">
            <Lottie options={defaultOptions} height={30} width={30} />
            <p class="small-print">Available now</p>
          </LottieAnimation>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="scripting" style={{ zIndex: 9 }} />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>ScriptStream has revolutionized how we handle our scripting needs. It's efficient, cost-effective, and incredibly easy to use.</em>
              </p>
              <p className="font13 orangeColor textRight" style={{ marginTop: '10px' }}>
                Francis, Owner of Grow Channel
              </p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const LottieAnimation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  justify-content: flex-start;
  font-style: italic;
  [aria-label='animation'] {
    margin: 0 !important;
  }
  cursor: default;

  @media (max-width: 960px) {
    justify-content: center;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 20px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
