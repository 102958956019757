import React, { useState } from 'react';
import styled from 'styled-components';
// Components
import PricingTable from '../Elements/PricingTable';

import Lightning from '../../assets/img/cube.png';
import RightArrow from '../../assets/img/gif/fast-forward.gif';

export default function Pricing({trackEvent}) {
  const [activeTab, setActiveTab] = useState('standard');

  return (
    <Wrapper id="pricing">
      <Container>
        <PricingFlex>
          <PricingRight onClick={() => trackEvent("Clicks anywhere on Membership area")}>
            <PricingLeftHeading className="blackColorText">Membership</PricingLeftHeading>
            <TabsMenu>
              <Tab active={activeTab === 'standard'} onClick={() => setActiveTab('pro')}>
                <img class="light-icon" src={Lightning} alt="Pro" />
                <p>Pro</p>
              </Tab>
            </TabsMenu>
            <PricingTable
              icon="monitor"
              price={activeTab === 'standard' ? '$299.99/m' : '$299.99/m'}
              title={activeTab === 'standard' ? 'Pro' : 'Pro'}
              text={activeTab === 'standard' ? 'One request at a time. Pause or cancel anytime.' : 'One request at a time. Pause or cancel anytime.'}
              offers={[
                { name: activeTab === 'standard' ? 'One request at a time' : 'One request at a time', cheked: true },
                { name: 'Average 48 hour delivery', cheked: true },
                { name: 'Unlimited references', cheked: true },
                { name: 'Storyboard mindmap', cheked: true },
                { name: 'Unlimited B-roll footage', cheked: true },
                { name: activeTab === 'standard' ? '2 users max' : 'Unlimited users', cheked: true },
                { name: 'Pause or cancel anytime', cheked: true },
              ]}
              action={() => {
                trackEvent('Click "Get started" button on pricing table');
                setTimeout(() => window.open('https://buy.stripe.com/7sIdRY8IQ77U2OY3cd'), 100);
              }}
            />
          </PricingRight>
          <PricingLeft>
            <PricingLeftHeading>
              Join
              <br />
              ScriptStream
            </PricingLeftHeading>
            <PricingLeftBottom>
              <PricingLeftCard href="https://calendly.com/scriptstream/discovery" target="_blank">
                <PricingLeftCardContent>
                  <h5>Book a 15-min intro call</h5>
                  <p>Learn more about how ScriptStream works and how it can help you.</p>
                </PricingLeftCardContent>
                <img class="arrow-icon" src={RightArrow} alt="Arrow" />
              </PricingLeftCard>
              <PricingLeftCard href="#" target="_blank">
                <PricingLeftCardContent>
                  <h5>Refer a friend & earn (coming soon!)</h5>
                  <p>Earn 5% monthly recurring commissions for each referral.</p>
                </PricingLeftCardContent>
                <img class="arrow-icon" src={RightArrow} alt="Arrow" />
              </PricingLeftCard>
            </PricingLeftBottom>
          </PricingLeft>
        </PricingFlex>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
  background-color: #f8f8f8;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const PricingFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const PricingLeft = styled.div`
  width: 40%;
  color: var(--white);
  background-color: #000;
  background-image: linear-gradient(#000, #1d1d1d);
  border: 1px solid #000;
  border-radius: 8px;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  max-width: 480px;
  padding: 50px;
  display: flex;
  position: relative;
  overflow: hidden;
  transform: rotate(0);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.34), 0 50px 50px rgba(0, 0, 0, 0.2), inset 0 2px 1px rgba(255, 255, 255, 0.5);
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const PricingLeftHeading = styled.h1`
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 30px;
  color: white;

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

const PricingLeftBottom = styled.div`
  display: flex;
  flex-direction: column;
`;

const PricingLeftCard = styled.a`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  text-decoration: none;
  color: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
`;

const PricingLeftCardContent = styled.div`
  h5 {
    margin: 0 0 10px 0;
    color: black !important;
  }

  p {
    font-size: 14px;
    margin: 0;
    color: black !important;
  }
`;

const PricingRight = styled.div`
  width: 55%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 40px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TabsMenu = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const Tab = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.active ? '#ffdc26' : 'transparent')};
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  border-radius: 60px;
  transition: background-color 0.3s ease;
  color: black !important;

  &:hover {
    background-color: #f0f0f0;
  }
`;
