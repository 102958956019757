import React from 'react';
import styled from 'styled-components';
// Components
import ProjectBox from '../Elements/ProjectBox';
import FullButton from '../Buttons/FullButton';
// Assets
import AddImage1 from '../../assets/img/video-prod.png';
import ForYouImage from '../../assets/img/gif/social-care.gif';
import RateImage from '../../assets/img/gif/money-bag.gif';
import SteadyImage from '../../assets/img/gif/line-chart.gif';
import ClockImage from '../../assets/img/gif/clock.gif';
import CalendarImage from '../../assets/img/gif/calendar.gif';
import ProfileImage from '../../assets/img/gif/profile.gif';
import GiftImg from '../../assets/img/gift.png';
import Num1Image from '../../assets/img/number-1.png';
import Num2Image from '../../assets/img/number-2.png';
import Num3Image from '../../assets/img/number-3.png';
const AddImgWrapp1 = styled.div`
  display: flex;
  width: 40%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">The New Content Creation Process</h1>
            <br></br>
            <br></br>
          </HeaderInfo>
          <div className="col textCenter">
            <div className="row textCenter rowCenter">
              <AddImgWrapp1 className="flexCenter">
                <img src={AddImage1} alt="office" />
              </AddImgWrapp1>
              <div className="col textCenter icon-container">
                <div>
                  <ProjectBox img={Num1Image} title="Totally async" text="Don't like meetings? We don't either; so much so that we've outlawed them completely." action={() => alert('clicked')} />
                </div>
                <div>
                  <ProjectBox
                    img={Num2Image}
                    title="Manage with Dashboard"
                    text="Manage your content board using Dashboard. View active, queued and completed tasks with ease."
                    action={() => alert('clicked')}
                  />
                </div>
                <div>
                  <ProjectBox img={Num3Image} title="Invite your team" text="Invite your entire team, so anyone can submit requests and track their progress." action={() => alert('clicked')} />
                </div>
                <div style={{marginBottom: 40}}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container icon-container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Membership benefits</h1>
            <p className="font18">Perks so good you'll never need to go anywhere else for your content creation. Seriously.</p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox img={CalendarImage} title="Content board" text="Easily manage your content queue with our script dashboard." action={() => alert('clicked')} />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox img={ClockImage} title="Lightning fast delivery" text="Get your scripts one at a time in just a few days on average." action={() => alert('clicked')} />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox img={RateImage} title="Fixed monthly rate" text="No surprises here! Pay the same fixed price each month." action={() => alert('clicked')} />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox img={ForYouImage} title="Top-notch quality" text="Insane content quality at your fingertips whenever you need it." action={() => alert('clicked')} />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox img={SteadyImage} title="Flexible and scalable" text="Scale up or down as needed, and pause or cancel at anytime." action={() => alert('clicked')} />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox img={ProfileImage} title="Unique and all yours" text="Each of your scripts is made especially for you and is 100% yours." action={() => alert('clicked')} />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: '50px 0', width: '200px' }}>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
padding-top: 40px;
    text-align: center;
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
